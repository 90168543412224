<template>
  <button :disabled="disabled || loading">
      <SpinnerComponent
          :spinner-color="'var(--text-white)'"
          :type="'small'"
          v-if="loading"
      >
      </SpinnerComponent>
    <svg
        v-if="has_plus"
        xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-plus" width="18" height="18" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M12 5l0 14"></path><path d="M5 12l14 0"></path>
    </svg>
      <slot></slot>
  </button>
</template>

<script>
import SpinnerComponent from "@/components/new/buttons/SpinnerComponent";
export default {
  components: {SpinnerComponent},
  props: ['disabled', 'loading', 'has_plus'],
};
</script>

<style scoped>
  button{
    background-color: var(--primary);
    border: 1px solid var(--primary);
    color: var(--complementary-second);
    font-family: var(--headings-font-family);
    letter-spacing: 2px;
    text-transform: uppercase;
  }

  button:hover{
    background-color: var(--primary-700);
    border: 1px solid var(--primary-700);
    box-shadow: 0 0 8px #660066B2;
    z-index: 10;
  }

  button:active{
    background-color: var(--primary-800);
    border: 1px solid var(--primary-800);
    box-shadow: none;
  }

  button[disabled]{
    background-color: var(--primary-100);
    border: var(--primary-100);
    cursor: not-allowed;
    color: var(--complementary-second-100)
  }

  button[disabled]:hover{
    background-color: var(--primary-100);
    border: var(--primary-100);
    box-shadow: none;
  }
</style>