<template>
  <div class="perfil_cuenta_user">
    <form @submit.prevent="updateData" v-show="!formClave" >
      <div  class="content_user">
        <InputComponent
            :disabled="false"
            :placeholder="$t('placeholder.txt_nombre')"
            :type="'text'"
            :required="true"
            :bg_color="'var(--text-white)'"
            v-model="payload.first_name"
        >
        </InputComponent>
        <InputComponent
            :disabled="false"
            :placeholder="$t('placeholder.txt_apellido')"
            :type="'text'"
            :required="true"
            :bg_color="'var(--text-white)'"
            v-model="payload.last_name"
        >
        </InputComponent>
        <InputComponent
            :disabled="false"
            :placeholder="$t('placeholder.txt_numero')"
            :type="'tel'"
            :required="true"
            :bg_color="'var(--text-white)'"
            :minlength="'9'"
            :maxlength="'9'"
            v-model="payload.phone"
        >
        </InputComponent>
        <InputComponent
            :disabled="true"
            :placeholder="$t('placeholder.txt_correo_electronico')"
            :type="'email'"
            :required="false"
            :bg_color="'var(--text-white)'"
            v-model="user.email"
        >
        </InputComponent>
        <div class="input-componet">
          <InputComponent
              :disabled="user.national_id != null && user.national_id !== ''"
              :placeholder="$t('placeholder.txt_dni')"
              :type="'text'"
              :required="false"
              :bg_color="'var(--text-white)'"
              v-model="payload.national_id"
          >
          </InputComponent>
          <abbr title="Acumula puntos en todos los canales.">
            <svg @click="showMessageOne" class="message" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11 17H13V11H11V17ZM12 9C12.2833 9 12.5208 8.90417 12.7125 8.7125C12.9042 8.52083 13 8.28333 13 8C13 7.71667 12.9042 7.47917 12.7125 7.2875C12.5208 7.09583 12.2833 7 12 7C11.7167 7 11.4792 7.09583 11.2875 7.2875C11.0958 7.47917 11 7.71667 11 8C11 8.28333 11.0958 8.52083 11.2875 8.7125C11.4792 8.90417 11.7167 9 12 9ZM12 22C10.6167 22 9.31667 21.7375 8.1 21.2125C6.88333 20.6875 5.825 19.975 4.925 19.075C4.025 18.175 3.3125 17.1167 2.7875 15.9C2.2625 14.6833 2 13.3833 2 12C2 10.6167 2.2625 9.31667 2.7875 8.1C3.3125 6.88333 4.025 5.825 4.925 4.925C5.825 4.025 6.88333 3.3125 8.1 2.7875C9.31667 2.2625 10.6167 2 12 2C13.3833 2 14.6833 2.2625 15.9 2.7875C17.1167 3.3125 18.175 4.025 19.075 4.925C19.975 5.825 20.6875 6.88333 21.2125 8.1C21.7375 9.31667 22 10.6167 22 12C22 13.3833 21.7375 14.6833 21.2125 15.9C20.6875 17.1167 19.975 18.175 19.075 19.075C18.175 19.975 17.1167 20.6875 15.9 21.2125C14.6833 21.7375 13.3833 22 12 22ZM12 20C14.2333 20 16.125 19.225 17.675 17.675C19.225 16.125 20 14.2333 20 12C20 9.76667 19.225 7.875 17.675 6.325C16.125 4.775 14.2333 4 12 4C9.76667 4 7.875 4.775 6.325 6.325C4.775 7.875 4 9.76667 4 12C4 14.2333 4.775 16.125 6.325 17.675C7.875 19.225 9.76667 20 12 20Z" fill="currentColor"/>
            </svg>
          </abbr>
          <div v-if="messageOneVisible" class="messageTextOne">{{ $t('perfilmicuentauser.txt_acumula_puntos_en_todos_los_canales') }}</div>
        </div>
        <div class="input-componet">
          <InputComponent
              v-if="user.date_birthday != null && user.date_birthday !== ''"
              :disabled="user.date_birthday != null && user.date_birthday !== ''"
              :placeholder="$t('placeholder.txt_fecha_de_nacimiento')"
              :type="'text'"
              :required="false"
              :bg_color="'var(--text-white)'"
              v-model="fixed_birthday"
          >
          </InputComponent>
          <date-picker class="date"
                       v-if="user.date_birthday == null || user.date_birthday === ''"
                       v-model:value="payload.date_birthday"
                       lang="es"
                       type="date"
                       format="DD-MM-YYYY"
                       value-type="YYYY-MM-DD"
                       placeholder="Fecha de nacimiento"
          >
          </date-picker>
          <abbr title="Adquiere ofertas exclusivas por tu día.">
            <svg @click="showMessageTwo" class="message" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11 17H13V11H11V17ZM12 9C12.2833 9 12.5208 8.90417 12.7125 8.7125C12.9042 8.52083 13 8.28333 13 8C13 7.71667 12.9042 7.47917 12.7125 7.2875C12.5208 7.09583 12.2833 7 12 7C11.7167 7 11.4792 7.09583 11.2875 7.2875C11.0958 7.47917 11 7.71667 11 8C11 8.28333 11.0958 8.52083 11.2875 8.7125C11.4792 8.90417 11.7167 9 12 9ZM12 22C10.6167 22 9.31667 21.7375 8.1 21.2125C6.88333 20.6875 5.825 19.975 4.925 19.075C4.025 18.175 3.3125 17.1167 2.7875 15.9C2.2625 14.6833 2 13.3833 2 12C2 10.6167 2.2625 9.31667 2.7875 8.1C3.3125 6.88333 4.025 5.825 4.925 4.925C5.825 4.025 6.88333 3.3125 8.1 2.7875C9.31667 2.2625 10.6167 2 12 2C13.3833 2 14.6833 2.2625 15.9 2.7875C17.1167 3.3125 18.175 4.025 19.075 4.925C19.975 5.825 20.6875 6.88333 21.2125 8.1C21.7375 9.31667 22 10.6167 22 12C22 13.3833 21.7375 14.6833 21.2125 15.9C20.6875 17.1167 19.975 18.175 19.075 19.075C18.175 19.975 17.1167 20.6875 15.9 21.2125C14.6833 21.7375 13.3833 22 12 22ZM12 20C14.2333 20 16.125 19.225 17.675 17.675C19.225 16.125 20 14.2333 20 12C20 9.76667 19.225 7.875 17.675 6.325C16.125 4.775 14.2333 4 12 4C9.76667 4 7.875 4.775 6.325 6.325C4.775 7.875 4 9.76667 4 12C4 14.2333 4.775 16.125 6.325 17.675C7.875 19.225 9.76667 20 12 20Z" fill="currentColor"/>
            </svg>
          </abbr>
          <div v-if="messageTwoVisible" class="messageTextTwo">{{ $t('perfilmicuentauser.txt_adquiere_ofertas_exclusivas') }}</div>
        </div>
        <div class="gender">
          <div class="input-container">
            <WhiteSelectComponent
                :optionsSelect="genderOptions"
                :bg_color="'white'"
                :name="'selectGenderComponent'"
                :required=true
                :placeholder="$t('placeholder.txt_pronombre')"
                :valueObj="'name'"
                v-model="tempGender"
                @dataFiltered="consoleStuff"
            >
            </WhiteSelectComponent>
          </div>
        </div>
        <ButtonTexto
            class="button_delete_account"
            type="button"
            @click="showModalDeleteAccount=true"
        >
          <svg data-v-0e8a4984="" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path data-v-0e8a4984="" d="M7 21C6.45 21 5.97917 20.8042 5.5875 20.4125C5.19583 20.0208 5 19.55 5 19V6C4.71667 6 4.47917 5.90417 4.2875 5.7125C4.09583 5.52083 4 5.28333 4 5C4 4.71667 4.09583 4.47917 4.2875 4.2875C4.47917 4.09583 4.71667 4 5 4H9C9 3.71667 9.09583 3.47917 9.2875 3.2875C9.47917 3.09583 9.71667 3 10 3H14C14.2833 3 14.5208 3.09583 14.7125 3.2875C14.9042 3.47917 15 3.71667 15 4H19C19.2833 4 19.5208 4.09583 19.7125 4.2875C19.9042 4.47917 20 4.71667 20 5C20 5.28333 19.9042 5.52083 19.7125 5.7125C19.5208 5.90417 19.2833 6 19 6V19C19 19.55 18.8042 20.0208 18.4125 20.4125C18.0208 20.8042 17.55 21 17 21H7ZM17 6H7V19H17V6ZM9 17H11V8H9V17ZM13 17H15V8H13V17Z" fill="currentColor"></path></svg>
          {{ $t('perfil.txt_eliminar_cuenta') }}
        </ButtonTexto>
      </div>
      <div class="buttons">
        <ButtonPrimary
            type="submit"
            :loading="loadingCambiar">
          {{messagePerfil}}
        </ButtonPrimary>
        <!--        <ButtonSecundary-->
        <!--          type="button"-->
        <!--          v-show="!formClave"-->
        <!--          @click="formClave = !formClave">-->
        <!--          Cambiar clave-->
        <!--        </ButtonSecundary>-->
      </div>

    </form>
    <form @submit.prevent="resetearClave" class="formulario_clave" v-show="formClave" style="margin-top: 30px">
      <div class="formulario_clave_container">
        <div class="clave__password">
          <PasswordComponent
              :placeholder="$t('placeholder.txt_ingresa_la_contrasena_actual')"
              :required="true"
              :bg_color="'#F7F7F7'"
              :disabled="loadingCambiar"
              v-model="old_password"
          >
          </PasswordComponent>
          <PasswordComponent
              :placeholder="$t('placeholder.txt_ingresa_la_nueva_contasena')"
              :required="true"
              :bg_color="'#F7F7F7'"
              :disabled="loadingCambiar"
              v-model="new_password"
          >
          </PasswordComponent>
          <PasswordComponent
              :placeholder="$t('placeholder.txt_vuelve_a_ingresar_la_nueva_contrasena')"
              :required="true"
              :bg_color="'#F7F7F7'"
              :disabled="loadingCambiar"
              v-model="new_password2"
          >
          </PasswordComponent>
        </div>
        <p>{{ $t('perfilmicuentauser.txt_tu_nueva_contrasena_debe_tener') }}</p>
        <div class="clave__buttons">
          <ButtonSecundary
              type="button"
              @click="formClave=!formClave"
          >
            {{ $t('dropdownaddress.txt_regresar') }}
          </ButtonSecundary>
          <ButtonPrimary
              :loading="loadingCambiar"
              type="submit"
          >
            {{messagePass}}
          </ButtonPrimary>
        </div>
      </div>
    </form>
  </div>
  <transition name="slide-fade">
    <TwoButtonGenericModal
        v-if="showModalDeleteAccount"
        :title="this.$t('barralateralizquierda.txt_eliminar_mi_cuenta')"
        :content="this.$t('barralateralizquierda.txt_deseas_eliminar_tu_cuenta')"
        :accept="this.$t('barralateralizquierda.txt_si_eliminar')"
        :cancel="this.$t('barralateralizquierda.txt_no_seguir_aqui')"
        @close_modal="showModalDeleteAccount=false"
        @accepted_modal="deleteAccount"
    >
    </TwoButtonGenericModal>
  </transition>
</template>

<script>

import { mapState, mapMutations } from "vuex"
import UserServices from "@/services/user-services"
import User from "@/services/user-services"
import InputComponent from "@/components/new/inputs/InputComponent.vue";
import ButtonPrimary from "@/components/new/buttons/ButtonPrimary";
import moment from "moment";
import ButtonSecundary from "@/components/new/buttons/ButtonSecundary";
import PasswordComponent from "@/components/new/inputs/PasswordComponent.vue";
import WhiteSelectComponent from "@/components/new/inputs/WhiteSelectComponent.vue";
import ButtonTexto from "@/components/new/buttons/ButtonTexto.vue";
import TwoButtonGenericModal from "@/components/MODALS/TwoButtonGenericModal.vue";

export default {
  components: {
    TwoButtonGenericModal,
    ButtonTexto,
    ButtonSecundary,
    ButtonPrimary,
    InputComponent,

    PasswordComponent,
    WhiteSelectComponent
  },
  data () {
    return {
      payload: {
        first_name: "",
        last_name: "",
        phone: "",
        national_id: "",
        date_birthday: '',
        gender: null,
      },
      showModalDeleteAccount: false,
      tempGender: null,
      formClave:false,
      old_password: '',
      new_password: '',
      new_password2: '',
      loadingCambiar: false,
      fixed_birthday: '',
      genderOptions: [
        {
          name: this.$t('perfilmicuentauser.txt_ella'),
          value: 1},
        {
          name: this.$t('perfilmicuentauser.txt_el'),
          value: 0
        }
      ],
      messageOneVisible: false,
      messageTwoVisible: false,
    }
  },
  computed: {
    ...mapState(['user']),
    messagePass() {
      if (this.loadingCambiar) {
        return this.$t('paymentlzipay.txt_cargando')
      } else {
        return this.$t('buttons.txt_confirmar_contrasena')
      }
    },
    messagePerfil() {
      if (this.loadingCambiar) {
        return this.$t('paymentlzipay.txt_cargando')
      } else {
        return this.$t('buttons.txt_guardar_cambios')
      }
    },
  },
  mounted () {
    this.setupUserData()
  },
  methods: {
    async deleteAccount () {
      this.showModalDeleteAccount = false
      let response = await User.deleteAccount()

      if (response.data.success) {
        this.$toast.success(this.$t('perfil.txt_se_elimino_su_cuenta'))
      } else {
        this.$toast.info(this.$t('perfil.txt_su_usuario_ya_se'))
      }
      this.setAuthenticatedFalse()
      localStorage.clear()
      this.setWebVersion(parseInt(process.env.VUE_APP_CURRENT_VERSION))
      await this.$router.push("/")
    },
    ...mapMutations(['setUser', 'setAuthenticatedFalse', 'setWebVersion']),
    consoleStuff() {
      this.payload.gender = this.tempGender.value
      console.log("pay", this.payload)
    },
    setupUserData() {
      this.payload.first_name = this.user.first_name
      this.payload.last_name = this.user.last_name
      this.payload.phone = this.user.phone
      this.payload.national_id = this.user.national_id
      this.payload.gender = this.user.gender
      if (this.user.date_birthday) {
        this.payload.date_birthday = moment(this.user.date_birthday).format('YYYY-MM-DD')
        this.fixed_birthday = moment(this.user.date_birthday).format('DD-MM-YYYY')
      }
      if (this.user.gender != null) {
        if (this.user.gender === 0) {
          this.tempGender = this.genderOptions[1]
        } else {
          this.tempGender = this.genderOptions[0]
        }
      }
    },
    async updateData () {
      let int_array = [8, 12]
      if (this.payload.phone.length !== 9) {
        this.$toast.error(this.$t('toast.txt_numero_telefonico_debe_tener_9_digitos'))
        return
      }
      if (this.payload.national_id !== '' && this.payload.national_id != null) {
        this.payload.national_id = this.payload.national_id.trim()
        if (!int_array.includes(this.payload.national_id.length)) {
          this.$toast.error(this.$t('toast.txt_debe_ingresar_un_dni_o_ce_valido'))
          return
        }
      }
      try {
        this.loadingCambiar=true
        let response = await UserServices.updateInfoUser(this.payload)
        if (response.data.success) {
          console.log("response", response.data.data)
          this.setUser(response.data.data)
          this.$toast.success(this.$t('perfilmicuentauser.txt_se_actualizaron_sus_datos'))
          this.setupUserData()
          this.loadingCambiar=false
        }
      } catch (error) {
        this.$toast.error(this.$t('perfilmicuentauser.txt_hubo_un_error_al_actualizar'))
        console.log("error al actualizar", error)
        this.loadingCambiar=false
      }
    },
    async resetearClave() {
      if (this.new_password.length < 6) {
        this.$toast.error(this.$t('perfilcambiarclave.txt_la_contrasena_debe_tener'))
        return
      }
      if (this.new_password !== this.new_password2) {
        this.$toast.error(this.$t('perfilmicuentauser.txt_nueva_contrasena_debe_ser'))
        return
      }

      this.loadingCambiar = true
      let response = await User.setPassword({old_password: this.old_password, new_password: this.new_password})

      if(response.data.success) {
        console.log(response.data)
        this.$toast.success(this.$t('perfilcambiarclave.txt_se_cambio_su_contrasena'))
        this.loadingCambiar=false
        this.formClave=false
        return
      }
      console.log(response.data)
      this.$toast.error(response.data.message)
      this.loadingCambiar=false
    },
    showMessageOne() {
      this.messageOneVisible = !this.messageOneVisible;
    },
    showMessageTwo() {
      this.messageTwoVisible = !this.messageTwoVisible;
    },
  }
}
</script>

<style scoped>
.content_user{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 30px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.date{ width: 100%; }

.buttons{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.clave__password{
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  gap: 30px 60px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.clave__buttons{
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.clave__password{ width: 100%; }

.input-componet{
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  position: relative;
}

.gender{ width: 100%; }

.button_delete_account{ color: var(--error); }

.button_delete_account:hover{
  background-color: var(--error-100);
  color: var(--error)
}

.button_delete_account:active{
  background-color: var(--error-600);
  color: var(--text-white)
}

.message{ cursor: pointer; }

.messageTextOne,
.messageTextTwo{
  display: block;
  position: absolute;
  top: -30px;
  right: 30px;
  background-color: #f0f0f0;
  border-radius: 8px;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  font-size: 14px;
}

@media only screen and (min-width: 425px){
  .input-container{ width: 230px; }
  .clave__password,
  .input-container{ width: 300px; }
  .date{ width: 230px; }
}

@media only screen and (min-width: 768px){
  .input-container{ width: 100%; }
  .date{ width: 100%; }

  .buttons{ gap: 30px; }
}

@media only screen and (min-width: 1024px){
  .content_user{ gap: 30px 60px; }
  .input-container{ width: 225px; }
  .input-componet{
    position: relative;
    width: 225px;
    gap: 0;
  }
  .input-componet svg{
    position: absolute;
    top: 5px;
    right: -30px;
  }
  .date{ width: 225px; }
  .messageTextOne,
  .messageTextTwo{
    width: max-content;
    right: -10px;
  }
}
</style>