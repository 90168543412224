<template>
  <div
      v-for="(item, index) in options_filterChips"
      :key="`dark_radio_filter_chips__${name}_${index}`"
  >
    <input
        type="radio"
        :value="valueObj==null? item : item[valueObj]"
        :name="name"
        :id="`dark_radio_filter_chips__${name}__${index}`"
        style="display: none"
        :checked="valueObj==null? item === modelValue : item[valueObj] === modelValue"
        @click="toggleRadio(index)"
    >
    <label class="chip_personalized" :for="`dark_radio_filter_chips__${name}__${index}`">
      {{ item.name }}
    </label>
  </div>
</template>

<script>
import { defineComponent } from "vue"
export default defineComponent({
  name: "DarkRadioFilterChip",

  emits: ['update:modelValue', 'filterChanged'],

  props: ['options_filterChips', 'modelValue', 'name', 'valueObj'],

  setup(props, { emit }) {
    const toggleRadio = (index) => {
      let selectedValue
      if (props.valueObj != null) {
        selectedValue = props.options_filterChips[index][props.valueObj];
      } else {
        selectedValue = props.options_filterChips[index];
      }
      if (selectedValue === props.modelValue) {
        // Si el mismo valor está seleccionado, no se hace nada
        return
      }
      emit("update:modelValue", selectedValue);
      emit("filterChanged")
    };

    return { toggleRadio };
  },
})
</script>

<style scoped>
  input + .chip_personalized {
    position: relative;
    background-color: var(--complementary-second) !important;
    border: 1px solid var(--complementary-second) !important;
    color: var(--primary);
    font-weight: var(--medium);
    gap: 0;
    height: 100%;
    font-family: var(--headings-font-family);
    letter-spacing: 2px;
    text-transform: uppercase;
  }

  input + .chip_personalized:after {
    content: "";
    position: absolute;
    top: 9px;
    left: -55px;
    display: inline-block;
    z-index: 5;
    transition: all .3s ease;
    border-left: 50px solid transparent;
    border-right: 50px solid transparent;
    border-bottom: 20px solid var(--text-white);
    rotate: 90deg;
  }

  input + .chip_personalized:hover {
    background-color: var(--complementary-second-300) !important;
    border: 1px solid var(--complementary-second-300) !important;
  }

  input + .chip_personalized:not(:hover) { box-shadow: 0 2px 5px rgba(0, 0, 0, 0); }

  input + .chip_personalized:active {
    background-color: var(--complementary-second-700);
    border: 1px solid var(--complementary-second-700);
  }

  input:checked + .chip_personalized {
    background-color: var(--primary) !important;
    border: 1px solid var(--primary) !important;
    color: var(--complementary-second);
    font-weight: var(--medium);
  }

  input:checked + .chip_personalized:after{ content: none; }

  input:checked + .chip_personalized:before {
    content: url("../../../assets/images-new/simbolo_adiction_chip.svg");
    display: block;
    width: 24px;
    height: 24px;
    position: relative;
    top: -15px;
    left: -30px;
  }
</style>